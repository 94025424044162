import { FC, useState } from 'react';
import useStyles from './styles';
import CustomLoader from 'src/components/CustomLoader/CustomLoader';
import { CustomText } from 'src/components/Text/CustomText';
import { ConclusionItem } from 'src/components/ConclusionItem/ConclusionItem';
import { ConclusionDetails } from '../../../Conclusion/ConclusionDetails';
import { Spacer } from 'src/components/Spacer/Spacer';

interface DESyntheseConclusionListProps {
    loading: boolean;
    updateList: boolean;
    conclusions: any;
    diagEducId: number;
    patientId: number;
}

export const DESyntheseConclusionList: FC<DESyntheseConclusionListProps> = (props) => {
    const classes = useStyles();
    const { patientId, conclusions, loading } = props;
    const [open, setOpen] = useState(false);
    const [conclusionData, setConclusionData] = useState<any>(null);

    const detail = (data: any) => {
        setConclusionData(data);
        setOpen(true);
    };

    return (
        <div className={classes.container}>
            {loading ? (
                <div style={{ width: '100%', alignContent: 'center' }}>
                    <CustomLoader IsclassicList />
                </div>
            ) : conclusions?.length > 0 ? (
                <div style={{ overflowY: 'scroll', marginTop: 10 }}>
                    {conclusions?.map((item: any) => (
                        <ConclusionItem
                            key={item.id}
                            onPress={() => detail(item)}
                            title={item.priorityAxisCare}
                            program={item?.programme}
                        />
                    ))}
                </div>
            ) : (
                <CustomText style={{ textAlign: 'center', marginTop: 30, marginBottom: 30 }} fontSize={15}>
                    Aucune conclusion trouvée.
                </CustomText>
            )}

            <Spacer />

            <ConclusionDetails patientId={patientId} open={open} conclusionData={conclusionData} setOpen={setOpen} />
        </div>
    );
};
